import React from 'react';
import Spinner from "./Spinner";
import { Button } from "@/components/ui/button";
import { useTranslations } from "next-intl";
import { JSX, SVGProps } from "react";
import { LezgoArrow } from "./LezgoArrow";

interface SubmitButtonProps {
  isPending: boolean;
  isPlaceValid: boolean;
  isVariant?: boolean;
}


const LezgoButton = ({ isPending, isPlaceValid, isVariant = false }: SubmitButtonProps) => {
  const t = useTranslations('Home.BookingForm');

  return (
    <Button
      type="submit"
      className={`w-full ${isVariant && `col-span-2 sm:col-span-1 !h-full`} rounded-none h-[69px] bg-primary text-white text-2xl`}
      disabled={isPending || !isPlaceValid}
    >
      {t('lezGo')}
      {isPending ? <Spinner color="primary-foreground" /> : <LezgoArrow className="w-6 h-6" />}
    </Button>
  );
}

export default LezgoButton;