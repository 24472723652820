interface SpinnerProps {
  color?: string;
}

export default function Spinner({ color = 'primary' }: SpinnerProps) {
  return (
    <div className="flex items-center justify-center">
      <div className={`ml-2 h-4 w-4 animate-spin rounded-full border-4 border-${color} border-t-transparent`} />
    </div>
  )
}
