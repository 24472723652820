'use client'

import React, { createContext, useContext, useState, ReactNode } from 'react';

interface PlaceContextType {
  place: google.maps.places.PlaceResult | null;
  setPlace: React.Dispatch<React.SetStateAction<google.maps.places.PlaceResult | null>>;
}

const PlaceContext = createContext<PlaceContextType | undefined>(undefined);

export const PlaceProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [place, setPlace] = useState<google.maps.places.PlaceResult | null>(null);

  return (
    <PlaceContext.Provider value={{ place, setPlace }}>
      {children}
    </PlaceContext.Provider>
  );
};

export const usePlace = (): PlaceContextType => {
  const context = useContext(PlaceContext);
  if (context === undefined) {
    throw new Error('usePlace must be used within a PlaceProvider');
  }
  return context;
};
