'use client'

import React, { useEffect, useRef, useState } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import { Library } from '@googlemaps/js-api-loader';
import { cn } from "@/lib/utils";
import { usePlace } from './PlaceContext';
import { useTranslations } from "next-intl";
import { City } from "@/types";

const libs: Library[] = ["core", "maps", "places", "marker"];

interface AutocompleteSearchBarProps {
  onChange: (value: string) => void;
  city?: City;
}

const AutocompleteSearchBar: React.FC<AutocompleteSearchBarProps> = ({ onChange, city }) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);
  const { place, setPlace } = usePlace();
  const t = useTranslations('Home.BookingForm');
  const [value, setValue] = useState("");

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY!,
    libraries: libs,
    version: 'beta'
  });

  const getBoundsForCity = (city: City) => {
    switch (city) {
      case 'miami':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(25.709, -80.459),
          new google.maps.LatLng(25.909, -80.139)
        );
      case 'curacao':
        return new google.maps.LatLngBounds(
          new google.maps.LatLng(12.044, -69.11),
          new google.maps.LatLng(12.354, -68.75)
        );
      default:
        return null;
    }
  };

  const getAddressUntilFirstComma = (address: string) => {
    const commaIndex = address.indexOf(',');
    return commaIndex !== -1 ? address.substring(0, commaIndex) : address;
  };

  useEffect(() => {
    if (isLoaded && inputRef.current) {
      const bounds = getBoundsForCity(city!);
      const options: google.maps.places.AutocompleteOptions = {
        bounds: bounds || undefined,
        componentRestrictions: { country: city === 'curacao' ? 'cw' : 'us' },
      };

      autocompleteRef.current = new google.maps.places.Autocomplete(inputRef.current!, options);

      autocompleteRef.current.addListener('place_changed', () => {
        const place = autocompleteRef.current!.getPlace();
        setPlace(place);
        if (place.formatted_address) {
          const address = getAddressUntilFirstComma(place.formatted_address);
          setValue(address);
          onChange(address);
        }
      });

      if (city === "miami" || city === "curacao") {
        const geocoder = new google.maps.Geocoder();
        const airport = city === "miami" ? "Miami Airport" : "Curacao Airport";
        geocoder.geocode({ address: airport }, (results, status) => {
          if (status === google.maps.GeocoderStatus.OK && results && results[0]) {
            const place = results[0];
            setPlace(place);
            if (place.formatted_address) {
              const address = getAddressUntilFirstComma(place.formatted_address);
              setValue(address);
              onChange(address);
            }
          }
        });
      }
    }
  }, [isLoaded, onChange, setPlace, city]);

  useEffect(() => {
    if (value === "") {
      setPlace(null);
    }
  }, [value, setPlace]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value);
    onChange(e.target.value);
  };

  return (
    <div className='flex flex-col space-y-4'>
      <input
        ref={inputRef}
        id="autocomplete-input"
        placeholder={t('enterALocation')}
        type="text"
        value={value}
        onChange={handleInputChange}
        disabled={!isLoaded}
        className={cn(
          "flex w-full border-none bg-transparent text-sm font-semibold transition-colors",
          "text-slate-500 placeholder:text-slate-500 !text-2xl",
          "hover:text-accent  hover:placeholder:text-accent",
          "focus-visible:outline-none",
          "disabled:cursor-not-allowed disabled:opacity-50"
        )}
      />
    </div>
  );
};

export default AutocompleteSearchBar;
